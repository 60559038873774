import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/call-to-action"
import { IndexMeta } from "../data/seo-meta"

import banner from "../images/home/banner.svg"
import platform from "../images/home/platform.png"
import quote from "../images/home/quote.png"
import partnerImage from "../images/home/partner-image.svg"

import checkIcon from "../images/common/check_icon.svg"

import appalachianLogo from "../images/logos/appalachian_underwriters.png"
import firstConnectLogo from "../images/logos/first_connect_logo.png"
import londonUWLogo from "../images/logos/london_underwriters.png"
import accessOne80 from "../images/logos/access-one80.png"
import philadelphiaLogo from "../images/logos/philadelphia-insurance-companies.png"

import horizonLogo from "../images/home/investors/horizons.png"
import horizonLogoWebp from "../images/home/investors/horizons.webp"
import axaLogo from "../images/home/investors/axa_xl.png"
import axaLogoWebp from "../images/home/investors/axa_xl.webp"
import sompoLogo from "../images/home/investors/sompo.png"
import sompoLogoWebp from "../images/home/investors/sompo.webp"
import munichLogo from "../images/home/investors/munich_re.svg"
import munichLogoWebp from "../images/home/investors/munich_re.webp"
import coopLogo from "../images/home/investors/cooperators.svg"
import coopLogoWebp from "../images/home/investors/cooperators.webp"
import grupoSuraLogo from "../images/home/investors/grupo_sura.svg"
import grupoSuraLogoWebp from "../images/home/investors/grupo_sura.webp"
import jetBlueLogo from "../images/home/investors/jetblue.png"
import jetBlueLogoWebp from "../images/home/investors/jetblue.webp"
import overwriteTargetMarkersLogo from "../images/home/investors/overwrite-target-markers.png"
import overwriteTargetMarkersLogoWebp from "../images/home/investors/overwrite-target-markers.webp"
import wsiaLogo from "../images/home/investors/wsia.png"
import wsiaLogoWebp from "../images/home/investors/wsia.webp"

const weEmpower = [
  "Quotes in 30 seconds; bind in under 5 minutes",
  "Friction free online experiences",
  "Unlimited & free Certificates of Insurance",
  "Coverage for difficult to quote classes",
  "Optional coverages for Additional Insureds",
  "Incentive plans",
]

const partners = [
  { src: accessOne80, alt: "Access One80 logo" },
  { src: appalachianLogo, alt: "Appalachian Underwriters, Inc Logo" },
  { src: firstConnectLogo, alt: "First Connect Logo" },
  { src: londonUWLogo, alt: "London Underwriters Logo" },
  { src: philadelphiaLogo, alt: "Philadelphia Insurance Companies Logo" },
]

const investors = [
  { src: horizonLogo, alt: "Horizon Ventures Logo", webp: horizonLogoWebp },
  { src: axaLogo, alt: "AXA XL Logo", webp: axaLogoWebp },
  { src: sompoLogo, alt: "Sompo Logo", webp: sompoLogoWebp },
  { src: munichLogo, alt: "Munich RE Logo", webp: munichLogoWebp },
  { src: coopLogo, alt: "The Co-operators Logo", webp: coopLogoWebp },
  { src: grupoSuraLogo, alt: "Grupo Sura Logo", webp: grupoSuraLogoWebp },
  { src: jetBlueLogo, alt: "Jet Blue Logo", webp: jetBlueLogoWebp },
  { src: wsiaLogo, alt: "WSIA Logo", webp: wsiaLogoWebp },
  {
    src: overwriteTargetMarkersLogo,
    alt: "Overwrite Target Markers Logo",
    webp: overwriteTargetMarkersLogoWebp,
  },
]

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        description={IndexMeta.description}
        path={IndexMeta.path}
        title={IndexMeta.title}
        hideSiteTitle
      />
      <div className="container py-20 md:py-24">
        <h1 className="h1 text-center mb-4">
          Where Innovation Shapes Insurance
        </h1>
        <h2 className="h3 text-center mb-10">
          Data-driven insurance for all types of small businesses.
          <br />
          Seamlessly automated, online, and hassle-free.
        </h2>
        <picture className="flex justify-center mb-10">
          <img src={banner} alt="Girl holding flowers" />
        </picture>

        <div className="flex flex-wrap">
          <div className="basis-full md:basis-1/2 md:pr-24">
            <p className="p1 mb-4">
              We partner with MGAs and Wholesalers to distribute our white
              labeled or co&#8209;branded small business insurance products on
              our cloud&#8209;based platform.
              <br />
              <br />
              <CallToAction
                label="Become a distribution partner"
                to="/contact/"
              />
            </p>
          </div>
          <div className="basis-full md:basis-1/2">
            <img
              className="md:-mt-6"
              src={platform}
              alt="Mockup picture of the Slice Platform"
            />
          </div>
        </div>

        <h2 className="h2 mt-36 mb-8 md:mb-16">
          Innovation that endures. Technology that delivers.
        </h2>
        <div className="flex flex-wrap">
          <div className="basis-full md:basis-1/2 order-2 md:order-1">
            <img
              className="mx-auto md:ml-4 md:-mt-8"
              src={quote}
              alt="Quote preview"
            />
          </div>
          <div className="basis-full md:basis-1/2 order-1 md:order-2 md:pl-8">
            <p className="p1">
              Built with AI, ML, behavioral science, and Large Language Models,{" "}
              <Link className="underline" to="/slice-mind/">
                Slice Mind and our Insurance Cloud Services (ICS) platform
              </Link>
              , we offer the following on-demand small business insurance
              products:{" "}
            </p>
            <ul className="p1 my-4 list-disc list-inside">
              <li>
                <Link
                  className="underline"
                  to="/contractors-general-liability/"
                >
                  Contractors General Liability
                </Link>{" "}
                (2 products)
              </li>
              <li>
                <Link className="underline" to="/workers-compensation/">
                  Workers’ Compensation
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <h2 className="h2 mt-24 mb-12">
          We empower agents to protect small businesses by offering:
        </h2>
        <div className="grid gap-10 md:grid-cols-3 mb-12">
          {weEmpower.map((content) => (
            <div
              className="px-10 py-16 rounded-2xl bg-[#f8f8f8] drop-shadow"
              key={content}
            >
              <img className="mb-8" src={checkIcon} alt="Plus icon" />
              <p className="p1 mb-4">{content}</p>
            </div>
          ))}
        </div>
        <div className="mb-40">
          <CallToAction label="Become a distribution partner" to="/contact/" />
        </div>

        <h2 className="h2 mb-12">Featured partners.</h2>
        <div className="flex flex-wrap justify-center">
          {partners.map((img) => (
            <div
              className="flex items-center justify-center h-[20rem] w-[20rem] m-4 rounded-2xl bg-[#f8f8f8] drop-shadow"
              key={img.src}
            >
              <img
                className="max-w-[14rem] max-h-[10rem]"
                src={img.src}
                alt={img.alt}
              />
            </div>
          ))}
        </div>

        <div className="flex justify-center mt-40 mb-24">
          <img src={partnerImage} alt="Two women rowing a boat" />
        </div>
        <div className="flex flex-col justify-center md:text-center mb-32">
          <h2 className="h1 mb-12">Ready to partner, Partner?</h2>
          <p className="p1 mb-12">
            Send us your email to schedule a demo or choose a time to connect.
          </p>
          <div>
            <CallToAction label="Connect with us" to="/contact/" />
          </div>
        </div>

        <hr />

        <h2 className="h3 mt-16 mb-6">
          Trusted by global partners and customers.
        </h2>
        <div className="inline-grid gap-8 grid-cols-3 md:grid-cols-5">
          {investors.map((partner) => (
            <picture key={partner.alt} className="mx-auto">
              <source srcSet={partner.webp} type="image/webp" />
              <img
                className="m-4 max-w-sm max-h-16"
                src={partner.src}
                alt={partner.alt}
              />
            </picture>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
